<template>
  <div class="animated fadeIn vendor-create">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4 class="d-block mb-4"><i class="nav-icon icon-plus"></i> Create Preset <i class="ml-2 fa fa fa-question-circle" v-tooltip="'<p>1. Select a company</p><p>2. Select a project</p><p>3. Select a well</p><p>4. Add your service line items</p><p>5. Assign AFE / PO and codes to each line items (This is optional for vendors)</p>'"></i></h4>
          <h4>Preset Template Name: </h4>
          <input class="template_name" type="text" v-model="it_name">
          <b-row class="mb-4 clearfix">
            <b-col class="col-12 mb-3 col-md-12 col-lg-4">
              <h4 class="mb-3">Billing From:</h4>
              <hr>
              <div v-if="vendorAvatar" class="mt-3 mb-3">
                <img class="companylogo" v-bind:src="vendorAvatar" />
              </div>
              <h5>{{vendorDetails.company_name}}</h5>
              <p>{{vendorDetails.fname}} {{vendorDetails.lname}}</span></p>
              <ul class="fa-ul invoice-detail-lists">
                <li><span class="fa-li"><i class="fa fa-building"></i></span>{{vendorDetails.addressline1}}</li>
                <li v-if="vendorDetails.addressline2"><span class="fa-li"><i class="fa fa-building"></i></span>{{vendorDetails.addressline2}}</li>
                <li><span class="fa-li"><i class="fa fa-building"></i></span>{{vendorDetails.city}}, {{vendorDetails.province}}</li>
                <li class="text-uppercase"><span class="fa-li"><i class="fa fa-building"></i></span>{{vendorDetails.postalcode}}</li>
                <li><span class="fa-li"><i class="fa fa-phone fa-flip-horizontal"></i></span>{{vendorDetails.phone_num}}</li>
                <li v-if="vendorDetails.fax_num"><span class="fa-li"><i class="fa fa-fax"></i></span>{{vendorDetails.fax_num}}</li>
                <hr>
                <li><span class="fa-li"><i class="fa fa-university"></i></span>GST#: {{vendorDetails.gst}}</li>
                <li><span class="fa-li"><i class="fa fa-university"></i></span>RCN#: {{vendorDetails.rcn}}</li>
                <li><span class="fa-li"><i class="fa fa-university"></i></span>Bank Account#: {{vendorDetails.bankaccount}}</li>
              </ul>
              <!-- <router-link class="btn btn-secondary btn-sm" :to="{ name: 'Vendor Profile' }"><i class="fa fa-user"></i> Profile</router-link> -->
            </b-col>
            <b-col class="col-12 col-md-6 col-lg-4">
              <h4 class="mb-3">Bill To:</h4>
              <hr>
              <div class="mt-3 mb-4" v-if="avatar">
                <img class="companylogo" v-bind:src="avatar" />
              </div>
              <div class="mb-4">
                <select :disabled="serviceData.length > 0" class="rt-select w-75" v-model="companyId" @change="get_projects">
                  <option value="0" disabled>Select Company</option>
                  <option v-for="company in companyData" :value="company">{{company.cn}}</option>
                </select>
              </div>
              <ul class="fa-ul invoice-detail-lists" v-if="companyId && !loadingCompany">
                <li><span class="fa-li"><i class="fa fa-building"></i></span>{{companyInfo.addressline1}}</li>
                <li v-if="companyInfo.addressline2"><span class="fa-li"><i class="fa fa-building"></i></span>{{companyInfo.addressline2}}</li>
                <li><span class="fa-li"><i class="fa fa-building"></i></span>{{companyInfo.city}}, {{companyInfo.province}}</li>
                <li><span class="fa-li"><i class="fa fa-building"></i></span>{{companyInfo.postalcode}}</li>
              </ul>
            </b-col>
            <b-col class="col-12 col-md-6 col-lg-4">
              <h4 class="mb-3">Details:</h4>
              <hr>
              <h5 class="mb-3">Date:</span> {{ new Date() | moment("MM/DD/YYYY") }}</h5>
              <h6 class="font-weight-normal"></h6>

              <div class="my-2" v-if="companyId">
                <label>Project Name</label>
                <select :disabled="serviceData.length > 0" class="rt-select w-75" v-model="projectid" name="" id="" @change="get_services(projectid.projectid)">
                  <option :value="option" v-for="option in projectData">{{option.projectname}}</option>
                </select>
              </div>
              <div class="my-2" v-if="companyId && vwref">
                <label>Well</label>
                <select class="rt-select w-75" v-model="vwref">
                  <option value="1" disabled>Select Well</option>
                  <option :value="option" v-for="option in uwiData">
                    <span v-if="option.uwi_3">({{option.uwi_3}}) - </span><span v-if="option.uwi_2">({{option.uwi_2}}) - </span><span v-if="option.uwi">({{option.uwi}})</span>
                  </option>

                </select>

                <ul class="fa-ul invoice-detail-lists mt-2" v-if="vwref !== 1 && vwref !== '1'">
                  <li><span class="fa-li"><i class="fa fa-hashtag"></i></span>License Number: {{vwref.ln}}</li>
                  <li><span class="fa-li"><i class="fa fa-location"></i></span>Surface Location: {{vwref.lsdsurface}}</li>
                  <li v-if="vwref.lsdbottom !== '----'"><span class="fa-li"><i class="fa fa-location"></i></span>Bottom Location: {{vwref.lsdbottom}}</li>
                </ul>

              </div>
            </b-col>
          </b-row>
          <b-modal ref="service-modal" class="service-modal" centered size="lg" hide-footer :title="'Select Your Service From The Following'">
            <v-client-table class="table vendor-service-select-table" :columns="columns" :data="vendorServices" :options="options" id="dataTable">
                <div slot="name" slot-scope="props">
                      <span>
                        <input class="mr-2" v-if="!vendoreService_toggler.includes(props.row.serviceid)" type="checkbox" @click="selectService(props.row)">
                      <i v-else @click="deselectService(props.row)" class="fa fa-trash mr-2" style="color:#F64947"></i>
                      {{props.row.name}}
                      </span>
                    </div>

                    <div slot="type" slot-scope="props">
                      <span>{{props.row.type}}</span>
                    </div>
                      
                       <div slot="uom1" slot-scope="props">
                      <span>{{props.row.uom1 !== null ? '$'+props.row.pu1+'/' +props.row.uom1: '-'}}</span>
                    </div>

                         <div slot="uom2" slot-scope="props">
                      <span>{{props.row.uom2 !== null ? '$'+props.row.pu2 +'/' +props.row.uom2 : '-'}}</span>
                    </div>

                         <div slot="uom3" slot-scope="props">
                      <span>{{props.row.uom3 !== null ? '$'+props.row.pu3+'/' +props.row.uom3: '-'}}</span>
                    </div>
            </v-client-table>
          </b-modal>
        </b-card>
        <b-card v-if="projectid">
            <h3 class="d-inline">Services</h3>
            <div id ="date-picker-div">
                         <v-date-picker
                        v-if="preServiceData.length > 0"
                          class="vc-calendar"
                          :formats='formats'
                          v-model='allService_dates'
                          mode='range'
                          show-caps
                          v-b-tooltip.hover
                          @input="setDates()"
                            :input-props='{
                          placeholder: "Apply date to all services",
                        }'
                        >
                        </v-date-picker>
                        
            <b-button :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1" v-if="preServiceData.length > 0" @click="addAllLines()" class="btn-blue mb-2 btn-sm float-right" href="">
              Save All To Invoice <i class="fa fa-save"></i>
            </b-button>
              </div>
            <div class="table-responsive-sm clearfix">
              <form @submit.prevent="addPreService" class="position-relative">
                <div id="loading-gif-container" v-show="loading">
                  <rotate-square2 id="loading-gif"></rotate-square2>
                </div>
                <table class="table table-striped">
                  <thead class="d-none d-xl-table-header-group">
                    <tr class="service-rows" id="service-th">
                      <th class="actions fit"></th>
                      <th class="center service-name">Service</th>
                      <th class="center service-name">Code</th>
                      <th class="pre-datepickers right">Start - End Date</th>
                      <th class="uom-select">UOM <i class="fa fa-question-circle" v-b-tooltip.hover title="Unit Of Measurement"></i></th>
                      <th class="price center">Price/Unit</th>
                       <th class="price center">Discounted Price/Unit</th>
                      <th class="price center">Quantity</th>
                      <th class="price center">Discount (%)</th>
                      <th class="right">Total</th>
                      <th class="save-button"></th>
                    </tr>
                  </thead>
                  
                  <tbody>
                    <tr class="service-rows create-service" v-for="(service, i) in preServiceData">
                      <td class="d-xs-none d-lg-block actions fit border-0"><button v-if="preServiceData.length > 0" type="button" :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1" v-on:click="deletePreLineServices(i)" class="btn btn-sm btn-danger"><i class="fa fa-minus"></i></button></td>
                      <td class="center service-name">
                        <h6 class="d-xs-block d-xl-none mobile-header">Service</h6>
                        <div v-if="service.selected">
                          {{service.serviceid.name}}
                        </div>
                     
                        <div class="d-xs-block d-xl-none">
                          <multiselect
                            v-model="service.serviceid"
                            class="rt-vendor-service-select"
                            label="name"
                            :multiple="false"
                            tagPlaceholder=""
                            track-by="serviceid"
                            selectLabel=""
                            deselectLabel=""
                            :options="vendorServices"
                            @input="(service.uom = [service.serviceid.pu1, service.serviceid.uom1])"
                          >
                          <template slot="option" slot-scope="props">
                              <div class="option__desc"><span class="option__title">{{ props.option.name }} ({{ props.option.currency }})</span></div>
                            </template>
                        </multiselect>
                        </div>
                      </td>
                      <td class="center service-name">
                        <h6 class="d-xs-block d-xl-none mobile-header">Code</h6>
                        <div v-if="service.selected">
                          {{service.serviceid.type}}
                        </div>
                       
                        <div class="d-xs-block d-xl-none">
                          <multiselect
                            v-model="service.serviceid"
                            class="rt-vendor-service-select"
                            label="type"
                            tagPlaceholder=""
                            :multiple="false"
                            selectLabel=""
                            deselectLabel=""
                            track-by="serviceid"
                            :options="vendorServices"
                            @input="(service.uom = [service.serviceid.pu1, service.serviceid.uom1])"
                          >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc"><span class="option__title">{{ props.option.type }}</span></div>
                            </template>
                          </multiselect>
                        </div>
                      </td>
                      <td class="pre-datepickers right">
                        <h6 class="d-xs-block d-xl-none">Start - End Date</h6>
                        <v-date-picker
                          v-if="service.serviceid.name"
                          class="vc-calendar"
                          :formats='formats'
                          mode='range'
                          v-model='service.dates'
                          show-caps
                          @input="setQuantity(i)"
                        >
                        </v-date-picker>
                      </td>
                      <td class="left uom-select">
                        <h6 class="d-xs-block d-xl-none">Unit Of Measurement</h6>
                        <select v-validate="'required'" class="w-100 rt-select" name="service.uom" @change = "Pu_toggler(service,service.discount,service.uom[0])"
                         v-if="service.serviceid.name" :class="{'is-danger': errors.has('service.uom')}" v-model="service.uom" placeholder="Select">
                          <option selected :value="[service.serviceid.pu1, service.serviceid.uom1]">{{service.serviceid.uom1}}</option>
                          <option v-if="service.serviceid.pu2" :value="[service.serviceid.pu2, service.serviceid.uom2]">{{service.serviceid.uom2}}</option>
                          <option v-if="service.serviceid.pu3" :value="[service.serviceid.pu3, service.serviceid.uom3]">{{service.serviceid.uom3}}</option>
                        </select>
                      </td>

                      <td class="price left v-align-middle">
                        <h6 class="d-xs-block d-xl-none" style="margin-bottom: 13px;">Price</h6>
                        <span v-if="service.serviceid.name">${{formatPrice(service.uom[0])}}</span>
                      </td>
                     
                        <td class="price left v-align-middle">
                        <h6 class="d-xs-block d-xl-none" style="margin-bottom: 13px;"> Discounted Price/Unit</h6>
<input v-if = "service.serviceid.name"  v-model ="service.discounted_price_per_unit" style="width:110px;"
   @input="Discount(service,service.discounted_price_per_unit,service.uom[0])">          
                      </td>

                      <td style="padding-bottom: 0.5rem;" class="price left">
                        <h6 class="d-xs-block d-xl-none">Quantity</h6>
                        <input v-if="service.serviceid.name" :class="{warning: /day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity}" step="0.01" class="rt-round" v-model="service.quantity">
                        <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity">
                          <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end (' + (parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
                        </span>
                      </td>
                      <td style="padding-bottom: 0.5rem;" class="price left">
                        <h6 class="d-xs-block d-xl-none">Discount (%)</h6>
<input type="text" v-if="service.serviceid.name" :id="`${service.serviceid.serviceid + service.uom[0]}`" class="rt-round" v-model="service.discount" @input="discounted(service,service.discount,service.uom[0])" style="width:110px;">
 <!-- popover meassage for discounted prie per unit -->
   
      <b-popover delay="1000000" :show ="service.discount > 100 || service.discount < 0" :target="`${service.serviceid.serviceid + service.uom[0]}`"  title="Warning">
      <span>Discount should be between 0%-100%</span><hr />
     <span>Discounted price per unit should be between $0-price/unit</span>
      </b-popover>                 
                      </td>
                      <td class="right v-align-middle">
                        <h6 class="d-xs-block d-xl-none" style="margin-bottom: 13px;">Total</h6>
                        <span v-if="service.serviceid.name">${{formatPrice((service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))}}</span>
                      </td>
                      <td class="save-button" style="margin-left:65px;">
                        <button :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1" @click="addPreService(service,i)" v-if="service.quantity >= 0 && service.uom && service.discount >= 0 && service.discount <= 100" type="submit" v-b-tooltip.hover title="Save Service To Invoice" class="btn btn-sm btn-blue d-xs-block d-xl-none"><i class="fa fa-save"></i></button>
                        <button :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1 || !service.selected" @click="addPreService(service, i)" v-if="service.quantity >= 0 && service.uom && service.discount >= 0 && service.discount <= 100" type="submit" v-b-tooltip.hover title="Save Service To Invoice" class="d-none d-xl-block btn btn-sm btn-blue" style="margin-left:15px;"><i class="fa fa-save"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                 <b-button v-if="projectid" :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1" @click="showServiceModal(preServiceData.length,'name','flag')" variant="success" class="mt-3 mb-3 btn-sm mr-2 float-left" href="">
                  Add New Line <i class="fa fa-plus"></i>
                </b-button>
                </div>
              </form>
            </div>
          </b-card>
          <b-card v-if="merged.length > 0 || unassigned.length > 0">
            <h3 class="d-inline mt-3">Service Lines <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="You may edit a service by clicking on it."></i></h3>
            <button type="button" class="btn btn-sm btn-danger float-right" @click="removeAll()" name="button"><strong>Remove All AFES <i class="fa fa-times"></i></strong></button>
            <b-row style="clear:both;">
              <b-col class="col-12 col-lg-12 mr-auto mt-2">
                <div class="table-responsive-sm">
                <!-- <rotate-square2 id="loading-gif" v-show="lineLoading"></rotate-square2> -->
                <table class="table vendor-invoice-unassigned vendor-service-table">
                  <thead class="d-none d-xl-table-header-group">
                    <tr>
                      <th class="border-0 actions fit"></th>
                      <th class="border-0">Service</th>
                      <th class="border-0">Code</th>
                      <th class="border-0 datepickers">Start - End Date</th>
                      <th class="border-0">UOM</th>
                      <th class="border-0">Price/Unit</th>
                       <th class="border-0"> Discounted Price/Unit</th>
                      <th class="border-0">Quantity</th>
                      <th class="border-0">Discount</th>
                      <th class="border-0">Total</th>
                      <th class="border-0"></th>
                    </tr>
                  </thead>
                  <tbody v-for="service in unassigned">
                    <tr class="service-rows mt-2 service-bg unassigned rt-wss-create-afe" :class="{editing: service == editedLine}">
                      <td style="line-height: 55px;" class="d-xs-none d-lg-block actions fit"><button type="button" :disabled="beforeEditCacheCatch == 1 || beforeEditAfeCacheCatch == 1" v-on:click="removeService(service)" class="btn btn-sm btn-danger"><i class="fa fa-minus"></i></button></td>
                      <td class="center price v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h4 class="d-xs-block d-xl-none">Service: {{service.name}}</h4>
                          <span class="d-none d-xl-block"><strong>{{service.name}}</strong></span>
                        </div>
                        <div class="edit">
                          <h4 class="d-xs-block d-xl-none">Service: {{service.name}}</h4>
                          <span class="mb-0 d-none d-xl-block">{{service.name}}</span>
                        </div>
                      </td>
                      <td class="center price v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h6 class="d-xs-block d-xl-none">Code: {{service.type}}</h6>
                          <span class="d-none d-xl-block">{{service.type}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Code: {{service.type}}</h6>
                          <span class="d-none d-xl-block">{{service.type}}</span>
                        </div>
                      </td>
                      <td class="center price datepickers v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h6 class="d-xs-block d-xl-none">Start - End Date</h6>
                          {{service.dates.start | moment("MM/DD/YYYY")}} - {{service.dates.end | moment("MM/DD/YYYY")}} <br><span style="font-size: 10px;">({{(parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1)}} Days)</span>
                        </div>
                        <div class="edit">
                          <v-date-picker
                            v-if="service == editedLine"
                            class="vc-calendar"
                            :formats='formats'
                            mode='range'
                            v-model='service.dates'
                            show-caps
                            @input="updateEditQuantity(service)"
                            >
                          </v-date-picker>
                        </div>
                      </td>
                      <td class="left price v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h6 class="d-xs-block d-xl-none">Unit Of Measurement: {{service.uom[1]}}</h6>
                          <span class="d-none d-xl-block">{{service.uom[1]}}</span>
                        </div>
                        <div class="edit">
                          <select v-validate="'required'" class="w-100 rt-select" @change = "Pu_toggler(service,service.discount,service.uom[0])"
                           name="service.uom" v-if="service.serviceid" :class="{'is-danger': errors.has('service.uom')}" v-model="service.uom" placeholder="Select">
                            <option selected :value="[service.serviceid.pu1, service.serviceid.uom1]">{{service.serviceid.uom1}}</option>
                            <option v-if="service.serviceid.pu2" :value="[service.serviceid.pu2, service.serviceid.uom2]">{{service.serviceid.uom2}}</option>
                            <option v-if="service.serviceid.pu3" :value="[service.serviceid.pu3, service.serviceid.uom3]">{{service.serviceid.uom3}}</option>
                          </select>
                        </div>
                      </td>
                      <td class="price left v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h6 class="d-xs-block d-xl-none">Price: ${{formatPrice(service.pu)}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice(service.pu)}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Price: ${{formatPrice(service.uom[0])}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice(service.uom[0])}}</span>
                        </div>
                      </td>

         <td class="price left v-align-middle">
                        <div class="view" @click="editData(service)">
                        
                         <h6 class="d-xs-block d-xl-none">Discounted Price/Unit:${{_discount_price_per_unit(service.discount,service.uom[0])}}</h6>
                           <span class="d-none d-xl-block">${{_discount_price_per_unit(service.discount,service.uom[0])}}</span>
                    
                      </div>
          
                        <div class="edit">

                          <h6 class="d-xs-block d-xl-none">Discounted Price/Unit:$
                  <input v-if = "service.name"  v-model ="service.discounted_price_per_unit"  @input="Discount(service,service.discounted_price_per_unit,service.uom[0])">                            

                    </h6>
  
<span class="d-none d-xl-block">$
<input v-if = "service.name" style="width:110px;" v-model ="service.discounted_price_per_unit"  @input="Discount(service,service.discounted_price_per_unit,service.uom[0])">   

  </span>
                      
   </div>
          
               </td>


                      <td class="price left v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h6 class="d-xs-block d-xl-none">Quantity: {{parseFloat(service.quantity)}}</h6>
                          <span class="d-none d-xl-inline-block">{{parseFloat(service.quantity)}}</span>
                          <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity">
                            <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end dates (' + (parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
                          </span>
                        </div>
                        <div class="edit">
                          <input :class="{warning: /day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity}" step="0.01" class="rt-round" v-model="service.quantity">
                          <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity">
                            <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end (' + (parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
                          </span>
                        </div>
                      </td>
                      <td class="price left v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h6 class="d-xs-block d-xl-none">Discount: {{formatPrice(service.discount)}}%</h6>
                          <span class="d-none d-xl-block">{{formatPrice(service.discount)}}%</span>
                        </div>
                        <div class="edit">
      <input type="text" style="width:110px;" :id="`${service.serviceid.ptn + service.uom[0]}`" v-if="service.name" class="rt-round" v-model="service.discount" @input="discounted(service,service.discount,service.uom[0])">

      <b-popover delay="60000" :show ="service.discount > 100 || service.discount < 0" :target="`${service.serviceid.ptn + service.uom[0]}`" title="Warning">
      <span>Discount should be between 0%-100%</span><hr/>
      <span>Discounted price per unit should be between $0-price/unit</span>
      </b-popover> 
                        </div>
                      </td>
                      <td class="right v-align-middle">
                        <div class="view" @click="editData(service)">
                          <h6 class="d-xs-block d-xl-none">Total: ${{formatPrice((service.quantity * price_per_unit) - ((service.discount/100) * service.quantity * price_per_unit))}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice((service.quantity * price_per_unit) - ((service.discount/100) * service.quantity * price_per_unit))}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Total: ${{formatPrice((service.quantity * price_per_unit) - ((service.discount/100) * service.quantity * price_per_unit))}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice((service.quantity * price_per_unit) - ((service.discount/100) * service.quantity * price_per_unit))}}</span>
                        </div>
                      </td>
                      <td>
                        <!-- <div class="view">
                          <b-dropdown variant="primary" size="sm" right :disabled="disableEdits == 1" text="Select Action" class="m-md-2 wss-action-dropdown">
                            <b-dropdown-item v-on:click="addNewUnassignedAfeLine(service, service)">AFE <i class="ml-1 fa fa-plus-circle"></i></b-dropdown-item>
                          </b-dropdown>
                        </div> -->
                        <div class="edit">
                          <button v-if="service.quantity >= 0 && service.uom && service.discount >= 0 && service.discount <= 100" class="edit btn btn-sm btn-primary" type="button" @click="editService(service)"><i class="fa fa-edit"></i></button>
                        </div>
                      </td>
                    </tr>
                    <tr :class="{createAfe: service != createdUnassignedServiceLine}" class="rt-wss-create-afe">
                      <td class="p-0 show" colspan="100%">
                        <div class="table-responsive-sm">
                          <table class="table no-bg afenum mb-0">
                            <thead class="rt-border d-none d-xl-table-header-group">
                              <tr class="afe-rows">
                                <th class="actions fit"></th>
                                <th class="center"><strong>AFE / PO</strong></th>
                                <th class="center"><strong>% <i class="fa fa-question-circle" v-b-tooltip.hover title="AFE Percentage"></i></strong></th>
                                <th class="center"><strong>Cost Code #1 / Major</strong></th>
                                <th class="center"><strong>Cost Code #2 / Minor</strong></th>
                                <th class="center"><strong>Cost Code #3 / Description</strong></th>
                                <th class="center"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <button type="button" v-on:click="cancelAfe()" v-b-tooltip.hover title="AFE Cancel" class="mt-1 fit btn btn-sm btn-danger"><i class="fa fa-times-circle"></i></button>
                                </td>
                                <td>
                                  <h6 class="d-xs-block d-xl-none">AFE / PO</h6>
                                  <v-select
                                    v-if="service == createdUnassignedServiceLine"
                                    v-model="createAfenum"
                                    label="afenum"
                                    :options="createAfeData"
                                    :value="afenum"
                                    @change="getcc1Create()"
                                  ></v-select>
                                </td>
                                <td>
                                  <h6 class="d-xs-block d-xl-none">Percentage</h6>
                                  <input type="number" class="rt-percent" step="any" v-model="percentage">
                                </td>
                                <td>
                                  <h6 class="d-xs-block d-xl-none">Cost Code #1 / Major</h6>
                                  <v-select
                                    v-if="service == createdUnassignedServiceLine"
                                    v-model="createCC1Code"
                                    label="ccone_code"
                                    :options="cc1CreateData"
                                    :value="ccone_code"
                                    @change="getcc2Create()"
                                  ></v-select>
                                </td>
                                <td>
                                  <h6 class="d-xs-block d-xl-none">Cost Code #2 / Minor</h6>
                                  <v-select
                                    v-if="service == createdUnassignedServiceLine"
                                    v-model="createCC2Code"
                                    label="cctwo_code"
                                    :options="cc2CreateData"
                                    :value="cctwo_code"
                                    @change="getcc3Create()"
                                  ></v-select>
                                </td>
                                <td>
                                  <h6 class="d-xs-block d-xl-none">Cost Code #3 / Description</h6>
                                  <v-select
                                    v-if="service == createdUnassignedServiceLine"
                                    v-model="createCC3Code"
                                    label="ccthree_code"
                                    :options="cc3CreateData"
                                    :value="ccthree_code"
                                  ></v-select>
                                </td>
                                <!-- <td class="actions">
                                  <button type="button" v-on:click="addAfeUnassignedLine(service)" class="btn btn-sm mt-1 btn-success mr-2">Add <i class="fa fa-plus"></i></button>
                                  <button type="button" v-on:click="addAfeToAllInvoiceServices(service)" class="btn btn-sm mt-1 btn-success">Add To All <i class="fa fa-plus"></i></button>
                                </td> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-for="(afe, index) in merged">
                    <tr class="service-rows mt-2 service-bg rt-wss-create-afe" v-for="(service, serviceIndex) in afe" :class="{editing: service == editedLine}">
                      <td style="line-height: 55px;" class="d-xs-none d-lg-block actions fit border-0"><button type="button" :disabled="disableEdits == 1" v-on:click="removeService(service)" class="btn btn-sm btn-danger"><i class="fa fa-minus"></i></button></td>
                      <td class="center price v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h4 class="d-xs-block d-xl-none">Service: {{service.name}}</h4>
                          <span class="d-none d-xl-block"><strong>{{service.name}}</strong></span>
                        </div>
                        <div class="edit">
                          <h4 class="d-xs-block d-xl-none">Service: {{service.name}}</h4>
                          <span class="mb-0 d-none d-xl-block">{{service.name}}</span>
                        </div>
                      </td>
                      <td class="center price v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Code: {{service.type}}</h6>
                          <span class="d-none d-xl-block">{{service.type}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Code: {{service.type}}</h6>
                          <span class="d-none d-xl-block">{{service.type}}</span>
                        </div>
                      </td>
                      <td class="center datepickers v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Start - End Date</h6>
                          {{service.dates.start | moment("MM/DD/YYYY")}} - {{service.dates.end | moment("MM/DD/YYYY")}} <br><span style="font-size: 10px;">({{(parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1)}} Days)</span>
                        </div>
                        <div class="edit">
                          <v-date-picker
                            v-if="service == editedLine"
                            class="vc-calendar"
                            :formats='formats'
                            @input="updateMergedEditQuantity(service, index, serviceIndex)"
                            mode='range'
                            v-model='service.dates'
                            show-caps
                            >
                          </v-date-picker>
                        </div>
                      </td>
                      <td class="left price v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Unit Of Measurement: {{service.uom[1]}}</h6>
                          <span class="d-none d-xl-block">{{service.uom[1]}}</span>
                        </div>
                        <div class="edit">
                          <select v-validate="'required'" class="w-100 rt-select" name="service.uom" 
                          @change = "Pu_toggler(service,service.discount,service.uom[0])"
                          v-if="service.serviceid" :class="{'is-danger': errors.has('service.uom')}" v-model="service.uom" placeholder="Select">
                            <option selected :value="[service.serviceid.pu1, service.serviceid.uom1]">{{service.serviceid.uom1}}</option>
                            <option v-if="service.serviceid.pu2" :value="[service.serviceid.pu2, service.serviceid.uom2]">{{service.serviceid.uom2}}</option>
                            <option v-if="service.serviceid.pu3" :value="[service.serviceid.pu3, service.serviceid.uom3]">{{service.serviceid.uom3}}</option>
                          </select>
                        </div>
                      </td>
                      <td class="price left v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Price: ${{formatPrice(service.pu)}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice(service.pu)}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Price: ${{formatPrice(service.uom[0])}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice(service.uom[0])}}</span>
                        </div>
                      </td>


            <td class="price left v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                        
                        <h6 class="d-xs-block d-xl-none">Discounted Price/Unit:${{formatPrice(service.discounted_price_per_unit)}}</h6>
                           <span class="d-none d-xl-block">${{formatPrice(service.discounted_price_per_unit)}}</span>
                    
                      </div>
          
                        <div class="edit">

                          <h6 class="d-xs-block d-xl-none">Discounted Price/Unit:$
<input v-if = "service.name" style="width:110px;" v-model ="service.discounted_price_per_unit"  @input="Discount(service,service.discounted_price_per_unit,service.uom[0])">                            

 </h6>
  
<span class="d-none d-xl-block">
<input v-if = "service.name" style="width:110px;"  v-model ="service.discounted_price_per_unit"  @input="Discount(service,service.discounted_price_per_unit,service.uom[0])">   

  </span>
                      
   </div>
          
   </td>

                      <td class="price left v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Quantity: {{parseFloat(service.quantity)}}</h6>
                          <span class="d-none d-xl-inline-block">{{parseFloat(service.quantity)}}</span>
                          <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity">
                            <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end dates (' + (parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
                          </span>
                        </div>
                        <div class="edit">
                          <input :class="{warning: /day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity}" step="0.01" class="rt-round" v-model="service.quantity">
                          <span class="ml-1" v-if="/day|Day/.test(service.uom) && $moment(service.dates.end).diff($moment(service.dates.start), 'days') + 1 != service.quantity">
                            <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end (' + (parseInt($moment(service.dates.end).diff($moment(service.dates.start), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
                          </span>
                        </div>
                      </td>
                      <td class="price left v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Discount: {{formatPrice(service.discount)}}%</h6>
                          <span class="d-none d-xl-block">{{formatPrice(service.discount)}}%</span>
                        </div>
                        <div class="edit">
                  <input type="text" style="width:110px;" :id="`${service.serviceid.ptn + service.uom[0]}`" v-if="service.name" class="rt-round" v-model="service.discount" @input="discounted(service,service.discount,service.uom[0])">

      <b-popover delay="60000" :show ="service.discount > 100 || service.discount < 0" :target="`${service.serviceid.ptn + service.uom[0]}`" title="Warning">
      <span>Discount should be between 0%-100%</span><hr/>
      <span>Discounted price per unit should be between $0-price/unit</span>
      </b-popover>
                        </div>

                      </td>

                      <td class="right v-align-middle" v-b-tooltip.hover title="please remove the AFE cost code from the service and then try to edit the fields.">
                        <div class="view">
                          <h6 class="d-xs-block d-xl-none">Total: ${{formatPrice((service.quantity * price_per_unit) - ((service.discount/100) * service.quantity * price_per_unit))}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice((service.quantity * price_per_unit) - ((service.discount/100) * service.quantity * price_per_unit))}}</span>
                        </div>
                        <div class="edit">
                          <h6 class="d-xs-block d-xl-none">Total: ${{formatPrice((service.quantity * price_per_unit) - ((service.discount/100) * service.quantity * price_per_unit))}}</h6>
                          <span class="d-none d-xl-block">${{formatPrice((service.quantity * price_per_unit) - ((service.discount/100) * service.quantity * price_per_unit))}}</span>
                        </div>
                      </td>
                      <td>
                        <!-- <div class="view">
                          <b-dropdown variant="primary" size="sm" right :disabled="disableEdits == 1" text="Select Action" class="m-md-2 wss-action-dropdown">
                             <b-dropdown-item v-on:click="addNewAfeLine(afe, service)">AFE <i class="ml-1 fa fa-plus-circle"></i></b-dropdown-item>
                             <b-dropdown-item v-on:click="removeAllAfe(service)">Remove All Assigned AFES <i class="ml-1 fa fa-times"></i></b-dropdown-item>
                          </b-dropdown>
                        </div> -->
                        <div class="edit">
                          <button v-if="service.quantity >= 0 && service.uom && service.discount >= 0 && service.discount <= 100" class="edit btn btn-sm btn-primary" type="button" @click="editService(service)"><i class="fa fa-edit"></i></button>
                        </div>
                      </td>
                    </tr>
                    <!-- AFES -->
                    <tr>
                      <td class="p-0" colspan="100%">
                        <div class="table-responsive-sm">
                          <table class="table no-bg afenum mb-0">
                            <thead class="rt-border  d-none d-xl-table-header-group">
                              <tr class="afe-rows">
                                <th class="actions fit"></th>
                                <th class="center"><strong>AFE / PO</strong></th>
                                <th class="center fit"><strong>% <i class="fa fa-question-circle" v-b-tooltip.hover title="AFE Percentage"></i></strong></th>
                                <th class="center"><strong>Cost Code #1 / Major</strong></th>
                                <th class="center"><strong>Cost Code #2 / Minor</strong></th>
                                <th class="center"><strong>Cost Code #3 / Description</strong></th>
                                <th class="centerx"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(line, aIndex) in afe[0].afes" :class="{editingAfe: line == editedAfeLine}" class="rt-wss-create-afe rt-mobile-indent">
                                <td class="d-xs-none d-lg-block actions fit border-0"></td>
                                <td>
                                  <div class="view">
                                    <h4 class="d-block d-xl-none">AFE</h4><button @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)" class="btn btn-primary d-inline-block d-xl-none btn-sm float-right"><i class="fa fa-edit"></i></button>
                                    <span @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)"><i class="fa fa-chevron-circle-right"></i> {{line.afenum}}</span>
                                  </div>
                                  <div class="edit" @click="editAfe = 0">
                                    <h6 class="d-xs-block d-xl-none">AFE / PO</h6>
                                    <v-select
                                      v-if="line == editedAfeLine"
                                      v-model="line.afenum"
                                      label="afenum"
                                      :options="serviceChainData"
                                      :value="afenum"
                                      @change="getcc1(index, aIndex, line)"
                                    ></v-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="view" @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)">
                                    <h6 class="d-xs-block d-xl-none">Percentage</h6>
                                    {{line.percentage}}
                                  </div>
                                  <div class="edit">
                                    <h6 class="d-xs-block d-xl-none">Percentage</h6>
                                    <input type="number" class="rt-percent" step="any" v-model="percentage">
                                  </div>
                                </td>
                                <td>
                                  <div class="view" @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #1 / Major</h6>
                                    {{line.ccone_code}}
                                  </div>
                                  <div class="edit" @click="editAfe = 0">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #1 / Major</h6>
                                    <v-select
                                      v-if="line == editedAfeLine"
                                      v-model="line.ccone_code"
                                      label="ccone_code"
                                      :options="cc1Data"
                                      :value="ccone_code"
                                      @change="getcc2(index, aIndex, line)"
                                    ></v-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="view" @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #2 / Minor</h6>
                                    {{line.cctwo_code}}
                                  </div>
                                  <div class="edit" @click="editAfe = 0">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #2 / Minor</h6>
                                    <v-select
                                      v-if="line == editedAfeLine"
                                      v-model="line.cctwo_code"
                                      label="cctwo_code"
                                      :options="cc2Data"
                                      :value="cctwo_code"
                                      @change="getcc3(index, aIndex, line)"
                                    ></v-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="view" @click="editAfe = 1, editAfeLine(line, index, aIndex, afes, afe)">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #3 / Description</h6>
                                    <span v-if="typeof(line.ccthree_code) == 'string' && !line.ccthree_code.includes('---')">{{line.ccthree_code}}</span>
                                  </div>
                                  <div class="edit" @click="editAfe = 0">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #3 / Description</h6>
                                    <v-select
                                      v-if="line == editedAfeLine"
                                      v-model="line.ccthree_code"
                                      label="ccthree_code"
                                      :options="cc3Data"
                                      :value="ccthree_code"
                                    ></v-select>
                                  </div>
                                </td>
                                <td class="actions">
                                  <div class="edit">
                                    <button type="button" v-on:click="editAddAfeToInvoice(line, afe, index, aIndex, afes)" class="btn btn-primary"><i class="fa fa-edit"></i></button>
                                  </div>
                                </td>
                              </tr>
                              <tr :class="{createAfe: afe != createdServiceLine}" class="rt-wss-create-afe">
                                <td class="show">
                                  <button type="button" v-on:click="cancelAfe()" v-b-tooltip.hover title="AFE Cancel" class="fit btn btn-sm btn-danger"><i class="fa fa-times-circle"></i></button>
                                </td>
                                <td>
                                  <div class="show">
                                    <h6 class="d-xs-block d-xl-none">AFE / PO</h6>
                                    <v-select
                                      v-if="afe == createdServiceLine"
                                      v-model="createAfenum"
                                      label="afenum"
                                      :options="createAfeData"
                                      :value="afenum"
                                      @change="getcc1Create()"
                                    ></v-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="show">
                                    <h6 class="d-xs-block d-xl-none">Percentage</h6>
                                    <input type="number" class="rt-percent" step="any" v-model="percentage">
                                  </div>
                                </td>
                                <td>
                                  <div class="show">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #1 / Major</h6>
                                    <v-select
                                      v-if="afe == createdServiceLine"
                                      v-model="createCC1Code"
                                      label="ccone_code"
                                      :options="cc1CreateData"
                                      :value="ccone_code"
                                      @change="getcc2Create()"
                                    ></v-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="show">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #2 / Minor</h6>
                                    <v-select
                                      v-if="afe == createdServiceLine"
                                      v-model="createCC2Code"
                                      label="cctwo_code"
                                      :options="cc2CreateData"
                                      :value="cctwo_code"
                                      @change="getcc3Create()"
                                    ></v-select>
                                  </div>
                                </td>
                                <td>
                                  <div class="show">
                                    <h6 class="d-xs-block d-xl-none">Cost Code #3 / Description</h6>
                                    <v-select
                                      v-if="afe == createdServiceLine"
                                      v-model="createCC3Code"
                                      label="ccthree_code"
                                      :options="cc3CreateData"
                                      :value="ccthree_code"
                                    ></v-select>
                                  </div>
                                </td>
                                <td class="actions">
                                  <div class="show">
                                    <button type="button" v-on:click="addAfeLine(afe)" class="btn btn-sm btn-success mr-2">Add <i class="fa fa-plus"></i></button>
                                    <button type="button" v-on:click="addAfeToAllInvoiceServices(index, afe)" class="btn btn-sm btn-success">Add To All <i class="fa fa-plus"></i></button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </b-col>
            </b-row>
          <b-row>
            <b-col lg="6" class="col-12 ml-auto">
            <table class="mt-1 table table-clear">
              <tbody>
                <tr>
                  <td class="left"><strong>Subtotal</strong></td>
                  <td class="right">${{formatPrice(subtotal)}}</td>
                </tr>
                <tr>
                  <td class="left"><strong>GST</strong></td>
                  <td class="right"><input class="days" type="number" min="0" max="100" v-model="gst" step="any"> %</td>
                </tr>
                <tr>
                  <td class="left"><strong>Tax</strong></td>
                  <td class="right">${{formatPrice(gstsubtotal)}}</td>
                </tr>
                <tr>
                  <td class="left"><strong>Total</strong></td>
                  <td class="right"><strong>${{formatPrice(totalinvoice)}}</strong></td>
                </tr>
                <tr>
                  <hr>
                </tr>
                <tr>
                  <td class="left"><strong>Subtotal</strong></td>
                  <td class="right">${{formatPrice(subtotal)}}</td>
                </tr>
                <tr>
                  <td class="left"><strong>Quick Pay Discount <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="Discount given to get paid quicker"></i></strong></td>
                  <td class="right">
                    <input type="number" class="days" min="0" max="100" step="any" v-model="quickpay"> %
                  </td>
                </tr>
                <tr v-if="quickpay > 0">
                  <td class="left"><strong>Quick Pay Discount Total</strong></td>
                  <td class="right">${{formatPrice(qpdiscount)}}</td>
                </tr>

                <tr v-if="quickpay > 0">
                  <td class="left"><strong>Quick Pay Subtotal</strong></td>
                  <td class="right">${{formatPrice(quickpaysubtotal)}}</td>
                </tr>

                <tr v-if="quickpay > 0">
                  <td class="left"><strong>Quick Pay Tax</strong></td>
                  <td class="right">${{formatPrice(qpgstsubtotal)}}</td>
                </tr>
                <tr v-if="quickpay > 0">
                  <td class="left"><strong>Quick Pay Net Days <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="Days given for quickpay payment"></i></strong></td>
                  <td class="right"><input class="days" type="number" value="0" min="0" v-model="days"></td>
                </tr>
                <tr v-if="quickpay > 0">
                  <td class="left"><strong>Quick Pay Total</strong></td>
                  <td class="right"><strong>${{formatPrice(quickpaysubtotal + qpgstsubtotal)}}</strong></td>
                </tr>
              </tbody>
            </table>
            <div style="padding-left: 0.75rem">
              <h5>Comments <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="Comments will be seen by all user levels and be attached on invoice"></i></h5>
              <textarea name="comments" v-model="comments" id="" class="w-100 rounded border mt-2 mb-4" rows="3"></textarea>

              <button type="submit" @click="submitInvoice(0)" class="btn btn-blue mb-4">Save Preset <i class="fa fa-save"></i></button>
              <div v-show="invoiceError" class="d-block">
                <i v-show="invoiceError" class="fa fa-exclamation-triangle"></i><span v-show="invoiceError" class="help">Invoice Error. Please fix invoice or contact admin.</span>
              </div>
            </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import { Validator } from 'vee-validate';
import {Circle4} from 'vue-loading-spinner'
import {RotateSquare2} from 'vue-loading-spinner'
import Multiselect from 'vue-multiselect'
import {ClientTable} from 'vue-tables-2'

import * as jwt_decode from 'jwt-decode'
Vue.use(ClientTable)

export default {
  name: 'VendorCreatePreset',
  components: {
    vSelect,
    Datepicker,
    RotateSquare2,
    Circle4,
    Multiselect
  },
  created() {

  },
  data () {
    return {
      avatar: "",
      vendorAvatar: "",
       columns: ['name','type','uom1','uom2','uom3'],
      options: {
        headings: {
          name: "Service Name",
          type: "Code",
          uom1: "Uom1",
          umo2: "Uom2",
          uom3: "Uom3"
        },
        sortable: ['name', 'type'],
        filterable: ['name', 'type'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        }
      },
      showCode: false,
      vendoreService_toggler:[],
      serviceSelectInModal: [],
      serviceModalIndex: 0,
      editedLine: null,
      editedAfeLine: null,
      editMode: false,
      gst: 5,
      preServiceData: [],
      serviceData: [],
      companyInfo: [],
      today: new Date(),
      uom: null,
      companyData: [],
      companyId: 0,
      projectid: 0,
      projectData: [],
      vendorDetails: [],
      name: 0,
      vwref: 1,
      start_date: '',
      end_date: '',
      quantity: 0,
      price: 0,
      loading: false,
      discount: 0,
      currency: 'CAD',
      pu: 0,
      afenum: '',
      ccone_code: '',
      cctwo_code: '',
      ccthree_code: '',
      addAfeButtons: 1,
      addAfeShow: 0,
      afe: [],
      percent: 0,
      afes: [],
      quickpay: 0,
      services: [],
      vendorServices: [],
      i: null,
      uomText: '',
      serviceText: '',
      comments: "",
      vsid: 0,
      days: "",
      addToInvoice: 1,
      chainServices: [],
      invoiceError: false,
      serviceChainData: [],
      afesSorted: [],
      uwiData: [],
      cc1Data: [],
      cc2Data: [],
      cc3Data: [],
      cc1: [],
      cc2: [],
      cc3: [],
      loadingCompany: false,
      servicenameSort: "",
      preIndex: 0,
      line: "",
      lineAfe: "",
      beforeEditCache: [],
      beforeEditCacheCatch: 0,
      beforeEditCacheIndex: 0,
      beforeEditAfeCache: null,
      beforeEditAfeCacheCatch: 0,
      beforeEditAfeCacheIndex: 0,
      price_per_unit:0,
      it_name: '',
      preset: [],
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'], // Only for `v-date-picker`
        dayPopover: 'L', // Only for `v-date-picker`
      },
      percentage: 0,
      createdServiceLine: 0,
      createAfenum: [],
      createCC1Percentage: 100,
      allService_dates:"",
      createCC1Code: [],
      createCC2Code: [],
      createCC3Code: [],
      createAfeData: [],
      cc1CreateData: [],
      cc2CreateData: [],
      cc3CreateData: [],
      createAfe: 0,
      disableEdits: 0,
      afes: [],
      order: "",
      ascDesc: 'asc',
      toggle: false,
      createdUnassignedServiceLine: [],
      createdServiceLineItem: [],
      editAfeChainLine: [],
      loading: false,
      lineLoading: false,
      disableImport: false,
      editAfe: 0,
      discounted_price_per_unit:0,
    }
  },
  watch: {
    preServiceData: function (val) {

    },
    companyId: function (val) {
      this.get_projects();
    }
  },
  methods: {

 Pu_toggler(service,discount,pu){


if(!discount){


return service.discounted_price_per_unit = pu.toFixed(2);// price per unit

}else{

 return service.discounted_price_per_unit = pu*(1-discount/100); 

}

},


Discount(service,discountedPrice,pu){


  service.discount = 100*(1-(discountedPrice/pu));  // pu- price per unit

  return service.discount;

  
},

discounted(service,discount,pu){


service.discounted_price_per_unit = pu*(1-discount/100); // pu- price per unit

return service.discounted_price_per_unit; 

},

  setDates(){

        this.preServiceData.map((value,index)=>{
         this.preServiceData[index].dates = this.allService_dates;
         this.setQuantity(index);
        });
     },

    showServiceModal(i, type,flag) {
     
      if(flag == 'flag'){
      
      this.serviceModalIndex = i;
      this.$refs['service-modal'].show();
      
      }
    },
    deselectService(service,pu){
        this.serviceSelectInModal = service;  
     
     let index = this.preServiceData.findIndex(services=> services.serviceid.serviceid == service.serviceid);
     let index1 = this.vendoreService_toggler.findIndex(services=> services == service.serviceid)
    this.preServiceData.splice(index,1);
    this.vendoreService_toggler.splice(index1,1);
    
   
   },

     selectService(service,pu){
      this.serviceSelectInModal = service;
      if(this.serviceSelectInModal.length == 0){
         this.$refs['service-modal'].hide();
        return null;
      }

       this.preServiceData.push(Vue.util.extend({}, {
        serviceid: [],
        dates: {"start": new Date, "end": new Date},
        uom: [],
        quantity: 1,
        discount: 0.00,
        vsid: 0,
        id: 0,
        percentage:  0,
        pu: 0,
        total: 0
      }));

      this.preServiceData[this.preServiceData.length-1].uom = [this.serviceSelectInModal.pu1, this.serviceSelectInModal.uom1];
      this.preServiceData[this.preServiceData.length-1].serviceid = this.serviceSelectInModal;
      this.preServiceData[this.preServiceData.length-1].selected = true;

     this.preServiceData[this.preServiceData.length-1].discounted_price_per_unit = this.serviceSelectInModal.pu1.toFixed(2);
     this.preServiceData[this.preServiceData.length-1].id = this.preServiceData.length;
     this.vendoreService_toggler.push(service.serviceid);
      
    },

    setQuantity(i){
      if(/day|Day/.test(this.preServiceData[i].uom)){
        var dates = this.preServiceData[i].dates;
        var days = this.$moment(dates.end).diff(this.$moment(dates.start), "days");

        this.preServiceData[i].quantity = days + 1;
      }
    },

    updateEditQuantity(service){
      var index = this.serviceData.findIndex(x=>x.vsid == service.vsid)

      if(/day|DAY|Day/.test(this.serviceData[index].uom)){
        var dates = this.serviceData[index].dates;
        var days = this.$moment(dates.end).diff(this.$moment(dates.start), "days");

        this.serviceData[index].quantity = days + 1;
      }
    },

    updateMergedEditQuantity(service, mergeIndex, serviceIndex){
      var index = this.serviceData.findIndex(x=>x.vsid == service.vsid)

      if(/day|DAY|Day/.test(this.merged[mergeIndex][serviceIndex].uom)){
        var dates = this.merged[mergeIndex][serviceIndex].dates;
        var days = this.$moment(dates.end).diff(this.$moment(dates.start), "days");

        // this.editedLine.quantity = days + 1;
        // this.serviceData[index].quantity = days + 1;
        // this.merged[mergeIndex][serviceIndex].quantity = days + 1;
      }
    },
    removeAllAfe: function (service) {
      this.afes = this.afes.filter(x=>x.vsid !== service.vsid)
    },
    addAfeLine(afes){
      if(this.percentage > 100 || this.percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }
      var cc_num = [];
      if(this.createCC3Code !== undefined && this.createCC3Code !== null){
        cc_num = this.createCC3Code.items[0];
      }
      else {
        cc_num = this.createCC2Code.items[0];
      }

      this.afes.push({
        vsid: this.createdServiceLineItem.vsid,
        afenum: cc_num.afenum,
        ccone_code: cc_num.ccone_code,
        cctwo_code: cc_num.cctwo_code,
        ccthree_code: cc_num.ccthree_code,
        percentage: this.percentage,
        service_ccid: cc_num.service_ccid,
      })
      this.createdServiceLine = 0;
      this.disableEdits = 0;
    },
    editData (line, index) {
      if(this.beforeEditCacheCatch == 1 || this.beforeEditAfeCacheCatch == 1){
        this.$toasted.show('Please save edit first', {type: 'error', duration: '3000'})
        return
      }

      this.beforeEditCache = Object.assign({}, line);
      this.editedLine = line
      this.beforeEditCacheCatch = JSON.parse(JSON.stringify(1));
    },
    // editAfeData (service, index) {
    //   if(this.beforeEditCacheCatch == 1 || this.beforeEditAfeCacheCatch == 1){
    //     this.$toasted.show('Please save edit first', {type: 'error', duration: '3000'})
    //     return
    //   }
    //   this.addAfeButtons = 0;
    //   this.editedAfeLine = service;
    //   this.beforeEditAfeCache = service;
    //
    //   this.cc1 = [];
    //   this.cc2 = [];
    //   this.cc3 = [];
    //   this.afe = [];
    //   this.vsid = service.vsid;
    //   this.percent = null;
    //   this.servicenameSort = service.service;
    //
    //   this.$axios.get("/vendor/get/service_chains/" + this.preset.companyid + "/" + this.preset.projectid + "/" + service.id)
    //   .then(response => {
    //     if(response.data.result.length > 0){
    //       var result = this.lodash.groupBy(response.data.result,'afenum')
    //       this.serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
    //
    //       var currentAfe = this.serviceChainData.findIndex(x=>x.afenum == service.afe)
    //       this.afe = this.serviceChainData[currentAfe];
    //       this.percent = service.percentage;
    //
    //
    //       //cc1
    //       var temp = this.afe.items;
    //       temp = this.lodash.groupBy(temp, 'ccone_code');
    //       this.cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
    //       this.cc1 = this.cc1Data[0];
    //
    //       //cc2
    //       var temp2 = this.cc1.items;
    //       temp2 = this.lodash.groupBy(temp2, 'cctwo_code');
    //       this.cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
    //       this.cc2 = this.cc2Data[0];
    //
    //       //cc3
    //       var temp3 = this.cc2.items;
    //       temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
    //       this.cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
    //       this.cc3 = this.cc3Data[0];
    //
    //       this.beforeEditAfeCacheCatch = JSON.parse(JSON.stringify(1));
    //       //this.addAfeShow = 1;
    //     }
    //   })
    //   .catch(error => {
    //     this.$toasted.show('No AFES', {type: 'error', duration: '3000'})
    //   })
    // },
    async editAddAfeToInvoice(line, service, serviceIndex, afeIndex, afes){
      if(this.percentage > 100 || this.percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }

      console.log(line, service, serviceIndex, afeIndex, afes);

      var cc_num = [];
      if(line.ccthree_code !== undefined && line.ccthree_code !== null){
        cc_num = line.ccthree_code.items[0];
      }
      else {
        cc_num = line.cctwo_code.items[0];
      }
      var find = this.afes.findIndex(x =>
        x.afenum == this.editAfeChainLine.afe_num &&
        x.ccone_code == this.editAfeChainLine.ccone_code &&
        x.cctwo_code == this.editAfeChainLine.cctwo_code &&
        x.ccthree_code == this.editAfeChainLine.ccthree_code &&
        x.percentage == this.editAfeChainLine.percentage &&
        x.vsid == service[0].vsid)

      console.log(cc_num)
      console.log(this.afes.filter(e => e.service_ccid === cc_num.service_ccid));

      if(this.afes.filter(e => e.service_ccid === cc_num.service_ccid).length > 0){
        if(cc_num.service_ccid == this.afes[find].service_ccid){
          console.log('same afe');
        }
        else{
          this.$toasted.show('AFE Duplicate', {type: 'error', duration: '3000'})
          return;
        }
      }

      Vue.set(this.afes, find, {
        afenum: cc_num.afenum,
        ccone_code: cc_num.ccone_code,
        cctwo_code: cc_num.cctwo_code,
        ccthree_code: cc_num.ccthree_code,
        percentage: this.percentage,
        service_ccid: cc_num.service_ccid,
        vsid: service[0].vsid,
      })

      this.disableEdits = 0;
      this.editedAfeLine = [];

    },
    // async editAddAfeToInvoice (afe, service) {
    //   if(this.percent > 100 || this.percent <= 0){
    //     this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
    //     return
    //   }
    //
    //   var cc_num = [];
    //   if(this.cc3 !== undefined && this.cc3 !== null){
    //     cc_num = this.cc3.items[0];
    //   }
    //   else {
    //     cc_num = this.cc2.items[0];
    //   }
    //
    //   if(this.afes.filter(e => e.service_ccid === cc_num.service_ccid).length > 0){
    //     if(cc_num.service_ccid == this.beforeEditAfeCache.service_ccid){
    //     }
    //     else{
    //       this.$toasted.show('AFE Duplicate', {type: 'error', duration: '3000'})
    //       return;
    //     }
    //   }
    //
    //   var find = this.afes.findIndex(function(item, i){
    //     return item.apid === service.apid
    //   });
    //
    //   let data = JSON.stringify({
    //     id: this.afes[find].apid,
    //     projectid: this.preset.projectid,
    //   })
    //
    //   this.afes[find] = {
    //     service: service.service,
    //     afe: cc_num.afenum,
    //     id: cc_num.serviceid,
    //     percentage: this.percent,
    //     vsid: service.vsid,
    //     service_ccid: cc_num.service_ccid,
    //     ccone_code: cc_num.ccone_code,
    //     cctwo_code: cc_num.cctwo_code,
    //     ccthree_code: cc_num.ccthree_code,
    //   }
    //
    //   this.afesSorted = this.lodash.chain(this.afes)
    //   .groupBy('service')
    //   .map((service, code) => ({ service, code }))
    //   .sortBy('code')
    //   .value();
    //
    //   this.addAfeButtons = 1;
    //   this.editedAfeLine = [];
    //   this.editedAfeLine = [];
    //   this.beforeEditAfeCacheCatch = 0;
    // },
    cancelEdit (index) {
      this.serviceData[this.beforeEditCacheIndex] = this.beforeEditCache
    },
    extendLine(){
      this.preServiceData.push(Vue.util.extend({}, {
        serviceid: [],
        dates: {"start": new Date, "end": new Date},
        uom: [],
        discount: 0,
        vsid: 0,
        id: 0,
        discount:  0,
        percentage:  0,
        quantity:  1,
        pu: [],
        total: 0,
      }))
    },
    cancelAfe: function () {
      this.addToInvoice = 1;
      this.addAfeButtons = 1;
      this.addAfeShow = 0;
      this.serviceChainData = [];
      this.beforeEditCacheCatch = 0;
      this.beforeEditAfeCacheCatch = 0;
      this.editAfe = 0;
      this.editedLine = [];
      this.editedAfeLine = [];
      this.createdUnassignedServiceLine = [];
      this.createdServiceLine = [];
      this.disableEdits = 0;
    },
    submitInvoice(draft) {

      if(this.vwref === 1){
        this.$toasted.show('Please select well', {type: 'error', duration: '3000'})
        return
      }
      if(this.it_name === ''){
        this.$toasted.show('Please add preset name', {type: 'error', duration: '3000'})
        return
      }
      if(this.serviceData.length === 0){
        this.$toasted.show('Please add services', {type: 'error', duration: '3000'})
        return
      }
      if(this.beforeEditCacheCatch == 1){
        this.$toasted.show('Please save service', {type: 'error', duration: '3000'})
        return
      }
      var qp = 0;
      if(this.quickpay > 0){
        qp = 1;
        if(this.days === ""){
          this.$toasted.show('Please add net days', {type: 'error', duration: '3000'})
          return
        }
      }
      var endtday = this.days;

      var sids = [];
      for (var i = 0; i < this.serviceData.length; i++) {
        var temp = {
          serviceid: this.serviceData[i].serviceid.serviceid,
          start_date: this.$moment(this.serviceData[i].dates.start).format('YYYY-MM-DD'),
          end_date: this.$moment(this.serviceData[i].dates.end).format('YYYY-MM-DD'),
          quantity: this.serviceData[i].quantity,
          uom: this.serviceData[i].uom[1],
          pu: this.serviceData[i].pu,
        
          discount: this.serviceData[i].discount,
          service_name: this.serviceData[i].serviceid.name,
          service_type: this.serviceData[i].serviceid.type,
          endtday: this.days,
          ln: this.vwref.ln,
          uwi: this.vwref.uwi,
          lsdsurface: this.vwref.lsdsurface,
          lsdbottom: this.vwref.lsdbottom
        }
        var afeTemp = this.afes.filter(x=>x.vsid == this.serviceData[i].vsid)
        console.log(afeTemp);
        var afes = []
        for (var x = 0; x < afeTemp.length; x++) {
          afes.push({
            percentage: afeTemp[x].percentage,
            service_ccid: afeTemp[x].service_ccid,
            category: afeTemp[x].category,
            afenum: afeTemp[x].afenum,
            ccone: afeTemp[x].ccone_code,
            cctwo: afeTemp[x].cctwo_code,
            ccthree: afeTemp[x].ccthree_code,
          })
        }
        console.log(temp)
        temp['afes'] = afes;
        sids.push(temp);
      }

      let data = JSON.stringify({
        it_name: this.it_name,
        companyid: this.companyId.companyid,
        projectid: this.projectid.projectid,
        tax_percentage: this.gst,
        discount: this.quickpay,
        vendor_note: this.comments,
        currency: this.serviceData[0].serviceid.currency,
        project_name: this.projectid.projectname,
        company_name: this.companyId.cn,
        ptn: this.projectid.ptn,
        vwref: this.vwref.uwi,
        discount: this.quickpay,
        quickpay: qp,
        endtday: this.$moment(new Date),
        sids: sids,
      })
      console.log(data)
      this.$axios.post('/vendor/create/invoice/template', data)
      .then(response => {
        this.$toasted.show('Preset Created Successfully', {type: 'success', duration: '3000'})
        this.invoiceError = false;
        this.$session.set('preset', '');
        this.$router.push('/vendor/presets')
      })
      .catch(error => {
        console.log(error)
        this.invoiceError = true;
      })
    },
    addPreService(service, i) {
      if(service.serviceid && service.uom){
        var total = this.formatPrice((service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))

        var uom = [service.uom[0],service.uom[1]];
        var number = 0;
        if(this.serviceData.length != 0){
          number = this.serviceData[this.serviceData.length-1].number + 1;
        }
        console.log(service);

        this.serviceData.push({
          id: service.serviceid.serviceid,
          serviceid: service.serviceid,
          name: service.serviceid.name,
          type: service.serviceid.type,
          dates:  service.dates,
          discounted_price_per_unit:service.discounted_price_per_unit,    
          discount:  service.discount,
          percentage:  0,
          quantity:  service.quantity,
          uom:  uom,
          pu: service.uom[0],
          total: total,
          vsid: Math.random()
        });
        // this.gsttotal();
  let index1 = this.vendoreService_toggler.findIndex(services=> services == this.preServiceData[i].serviceid.serviceid);
  this.vendoreService_toggler.splice(index1,1);
  Vue.delete(this.preServiceData, i);

      }
    },
    editService(service, i) {
      if(service.serviceid && service.uom){
        this.loading = true;
          var total = this.formatPrice((service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))

          var uom = [service.uom[0],service.uom[1]];
          var number = 0;
          if(this.serviceData.length != 0){
            number = this.serviceData[this.serviceData.length-1].number + 1;
          }
          this.removeService(i, 1);

          Vue.set(this.serviceData, i, {
            id: service.serviceid.serviceid,
            serviceid: service.serviceid,
            dates:  service.dates,
            discounted_price_per_unit:service.discounted_price_per_unit,
            discount:  service.discount,
            percentage:  0,
            quantity:  service.quantity,
            uom:  uom,
            pu: service.uom[0],
            total: total,
          });

          this.editedLine = [];
          this.beforeEditCacheCatch = 0;
          this.loading = false;
      }
    },
    async addAllLines() {
      this.loading = true;

      var count = this.preServiceData.length;
        if(count > 0){

        for (var i = 0; i < this.preServiceData.length; i++) {
          var service = this.preServiceData[i];
          console.log(service)

            if(service.serviceid.length != 0){
              var total = this.formatPrice((service.quantity * service.uom[0]) - ((service.discount/100) * service.quantity * service.uom[0]))
              if(service){
                var uom = [service.uom[0],service.uom[1]];

                this.serviceData.push({
                  id: service.serviceid.serviceid, 
                  serviceid: service.serviceid,
                  name: service.serviceid.name,
                  type: service.serviceid.type,
                  dates:  service.dates,
                  end_date:  service.end_date,
                  discounted_price_per_unit:service.discounted_price_per_unit,
                  discount:  service.discount,
                  percentage:  0,
                  quantity:  service.quantity,
                  uom:  uom,
                  pu: service.uom[0],
                  total: total,
                  vsid: Math.random()
                })
                this.vendoreService_toggler.length = 0;
                // this.gsttotal();
              }
          }
        }
        this.preServiceData = [];
      }
      this.loading = false;
    },
    deletePreLineServices(i){
      let index1 = this.vendoreService_toggler.findIndex(services=> services == this.preServiceData[i].serviceid.serviceid);
     this.vendoreService_toggler.splice(index1,1);
      Vue.delete(this.preServiceData, i);
    },
    removeService (index, edit, fullDelete) {
      var changed = 1;

      if(this.serviceData[index]){
        var id = this.serviceData[index].id
        var afesToRemove = this.afes.filter(x=>x.id === id)

        for (var i = 0; i < afesToRemove.length; i++) {
          if(this.beforeEditCache.serviceid && this.serviceData[index].serviceid.serviceid == this.beforeEditCache.serviceid.serviceid && fullDelete != 1){
            changed = 0;
            this.removeAfe(0, afesToRemove[i].apid, afesToRemove[i], changed)
          }
          else{
            changed = 1;
            this.removeAfe(0, afesToRemove[i].apid, afesToRemove[i], changed)
          }
        }
      }
      if(edit != 1){
        this.serviceData.splice(index, 1);
      }
      // this.gsttotal();
    },
    addNewAfe (id, vsid, servicename) {
      this.beforeEditAfeCacheCatch = 1;
      this.cc1 = [];
      this.cc2 = [];
      this.cc3 = [];
      this.afe = [];
      this.vsid = vsid;
      this.addToInvoice = 0;
      this.addAfeButtons = 0;
      this.percent = 100;
      this.servicenameSort = servicename;

      this.$axios.get("/vendor/get/service_chains/" + this.preset.companyid + "/" + this.preset.projectid + "/" + id)
      .then(response => {
        if(response.data.result.length > 0){
          var result = this.lodash.groupBy(response.data.result,'afenum')
          this.serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
          this.afe = this.serviceChainData[0];

          //cc1
          var temp = this.afe.items;
          temp = this.lodash.groupBy(temp, 'ccone_code');
          this.cc1Data = Object.keys(temp).map(key => ({ ccone_code: key, items: temp[key] }));
          this.cc1 = this.cc1Data[0];

          //cc2
          var temp2 = this.cc1.items;
          temp2 = this.lodash.groupBy(temp2, 'cctwo_code');
          this.cc2Data = Object.keys(temp2).map(key => ({ cctwo_code: key, items: temp2[key] }));
          this.cc2 = this.cc2Data[0];

          //cc3
          var temp3 = this.cc2.items;
          temp3 = this.lodash.groupBy(temp3, 'ccthree_code');
          this.cc3Data = Object.keys(temp3).map(key => ({ ccthree_code: key, items: temp3[key] }));
          this.cc3 = this.cc3Data[0];

          this.addAfeShow = 1;
        }
        if(response.data.result.length === 0){
          this.addAfeShow = 0;
          this.addToInvoice = 0;
          this.addAfeButtons = 1;
        }
      })
      .catch(error => {
        this.$toasted.show('No AFES', {type: 'error', duration: '3000'})
      })
    },
    // addAfeToInvoice: function (afe) {
    //   if(this.percent > 100 || this.percent <= 0){
    //     this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
    //     return
    //   }
    //   var cc_num = [];
    //   if(this.cc3 !== undefined && this.cc3 !== null){
    //     cc_num = this.cc3.items[0];
    //   }
    //   else {
    //     cc_num = this.cc2.items[0];
    //   }
    //   if(this.afes.filter(e => e.service_ccid === cc_num.service_ccid).length > 0){
    //     this.$toasted.show('AFE Duplicate', {type: 'error', duration: '3000'})
    //     return;
    //   }
    //
    //   this.afes.push({
    //     service: this.servicenameSort,
    //     category: cc_num.category,
    //     afe: cc_num.afenum,
    //     id: cc_num.serviceid,
    //     percentage: this.percent,
    //     service_ccid: cc_num.service_ccid,
    //     vsid: this.vsid,
    //     ccone_code: cc_num.ccone_code,
    //     cctwo_code: cc_num.cctwo_code,
    //     ccthree_code: cc_num.ccthree_code,
    //   })
    //
    //   this.addAfeShow = 0;
    //   this.afe = [];
    //   this.addAfeButtons = 1;
    //   this.beforeEditAfeCacheCatch = 0;
    // },
    removeAfe (index, apid, na, changed) {
      var find = this.afes.findIndex(function(item, i){
        return item.apid === apid
      });

      if(changed == 0){
        this.afes[find] = {
          service: this.afes[find].service,
          afe: this.afes[find].afe,
          percentage: this.afes[find].percentage,
          service_ccid: this.afes[find].service_ccid,
          ccone_code: this.afes[find].ccone_code,
          cctwo_code: this.afes[find].cctwo_code,
          ccthree_code: this.afes[find].ccthree_code,
        }

        this.afesSorted = this.lodash.chain(this.afes)
        .groupBy('service')
        .map((service, code) => ({ service, code }))
        .sortBy('code')
        .value();
      }
      else {
        Vue.delete(this.afes, find);
        this.afesSorted = this.lodash.chain(this.afes)
        .groupBy('service')
        .map((service, code) => ({ service, code }))
        .sortBy('code')
        .value();
      }
    },
    get_projects(){
      this.vendorServices = [];
      this.loadingCompany = true;
      this.get_company();
      this.$axios.get('/vendor/get/allowed_projects/' + this.companyId.companyid)
      .then(response => {
        if (response.status === 200) {
          this.projectData = response.data.result;
             this.price_per_unit = response.data.result[0].pu1.toFixed(2);
          if(this.projectData.length > 0){
            this.projectid = this.projectData[0];
            console.log(this.projectData[0].projectid);
            this.get_services(this.projectData[0].projectid);
          }
          this.loadingCompany = false;
        }
      })
    },
    addNewAfeLine(afe, service){
      console.log(service);
      this.$axios.get("/vendor/get/service_chains/" + this.companyId.companyid + "/" + this.projectid.projectid + "/" + service.id)
      .then(response => {
        if(response.data.result.length > 0){
          this.createdServiceLine = afe;
          this.createdServiceLineItem = service;
          this.percentage = 100;
          var result = this.lodash.groupBy(response.data.result, 'afenum')
          this.createAfeData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
          this.createAfenum = this.createAfeData[0];
          this.createAfe = 1;
          this.disableEdits = 1;
          return this.createAfeData[0];
        }
        else {
          this.createdServiceLine = 0;
          this.$toasted.show('No AFES Found', {type: 'error', duration: '3000'})
          return
        }
      }).then(response => {
        //cc1
        var afeData = this.lodash.groupBy(response.items, 'ccone_code');
        this.cc1CreateData = Object.keys(afeData).map(key => ({ ccone_code: key, items: afeData[key] }));
        this.createCC1Code = this.cc1CreateData[0];
        this.createAfe = 1;
        return this.cc1CreateData[0]
      }).then(response => {
        //cc2
        var cc1 = this.lodash.groupBy(response.items, 'cctwo_code');
        this.cc2CreateData = Object.keys(cc1).map(key => ({ cctwo_code: key, items: cc1[key] }));
        this.createCC2Code = this.cc2CreateData[0];
        this.createAfe = 1;
        return this.cc2CreateData[0];
      }).then(response => {
        //cc3
        if(response.items.length > 0){
          var cc3Temp = response.items.filter(o => !o.ccthree_code.includes('---'));
          var cc3Group = this.lodash.groupBy(cc3Temp, 'ccthree_code');
          this.cc3CreateData = Object.keys(cc3Group).map(key => ({ ccthree_code: key, items: cc3Group[key] }));
          this.createCC3Code = this.cc3CreateData[0];
          this.createAfe = 1;
        }
      })
      .then(response => {
        this.createAfe = 0;
      }).catch(error => {
        console.log(error)
      })
    },
    addAfeUnassignedLine(service){
      console.log(service);
      if(this.percentage > 100 || this.percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }
      var cc_num = [];
      if(this.createCC3Code !== undefined && this.createCC3Code !== null){
        cc_num = this.createCC3Code.items[0];
      }
      else {
        cc_num = this.createCC2Code.items[0];
      }

      this.afes.push({
        vsid: service.vsid,
        afenum: cc_num.afenum,
        ccone_code: cc_num.ccone_code,
        cctwo_code: cc_num.cctwo_code,
        ccthree_code: cc_num.ccthree_code,
        percentage: this.percentage,
        service_ccid: cc_num.service_ccid,
      })
      this.createdUnassignedServiceLine = [];
      this.disableEdits = 0;
    },
    editAfeLine (afe, serviceIndex, afeIndex, afes, service) {
      if(this.disableEdits == 1 || this.beforeEditCacheCatch == 1 || this.beforeEditAfeCacheCatch == 1){
        this.$toasted.show('Please save edit first.', {type: 'error', duration: '3000'})
        return
      }
      if(service.length > 1){
        this.$toasted.show('Cannot edit grouped cost code chains.', {type: 'error', duration: '3000'})
        return
      }
      console.log(afe)
      console.log(service)
      this.editAfeChainLine = {
        afe_num: afe.afenum,
        ccone_code: afe.ccone_code,
        cctwo_code: afe.cctwo_code,
        ccthree_code: afe.ccthree_code,
        percentage: afe.percentage,
      };
      this.editAfe = 1;

      this.$axios.get("/vendor/get/service_chains/" + this.preset.companyid + "/" + this.preset.projectid + "/" + service[0].id)
      .then(response => {
        if(response.data.result.length > 0){
          this.editedAfeLine = afe;
          this.percentage = afe.percentage;
          this.disableEdits = 1;

          var result = this.lodash.groupBy(response.data.result, 'afenum')
          this.serviceChainData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
          var currentAfe = this.serviceChainData.findIndex(x=>x.afenum == afe.afenum)
          this.merged[serviceIndex][0].afes[afeIndex].afenum = this.serviceChainData[currentAfe];
          this.editAfe = 1;
          return this.serviceChainData[currentAfe];
        }
        else {
          this.editedAfeLine = [];
          this.$toasted.show('No AFES Found', {type: 'error', duration: '3000'})
          return
        }
      }).then(response => {
        if(response.items.length > 0){
          //cc1
          var afeData = this.lodash.groupBy(response.items, 'ccone_code');
          this.cc1Data = Object.keys(afeData).map(key => ({ ccone_code: key, items: afeData[key] }));
          var cc1Index = this.cc1Data.findIndex(x=>x.ccone_code == afe.ccone_code)
          this.merged[serviceIndex][0].afes[afeIndex].ccone_code = this.cc1Data[cc1Index];
          this.editAfe = 1;
          console.log(this.cc1Data, cc1Index)
          return this.cc1Data[cc1Index]
        }
      }).then(response => {
        //cc2
        if(response.items.length > 0){
          var cc1 = this.lodash.groupBy(response.items, 'cctwo_code');
          this.cc2Data = Object.keys(cc1).map(key => ({ cctwo_code: key, items: cc1[key] }));
          var cc2Index = this.cc2Data.findIndex(x=>x.cctwo_code == afe.cctwo_code)
          this.merged[serviceIndex][0].afes[afeIndex].cctwo_code = this.cc2Data[cc2Index];
          this.editAfe = 1;

          return this.cc2Data[cc2Index];
        }
      }).then(response => {
        //cc3
        if(response.items.length > 0){
          var cc3Temp = response.items.filter(o => !o.ccthree_code.includes('---'));
          var cc3Group = this.lodash.groupBy(cc3Temp, 'ccthree_code');
          this.cc3Data = Object.keys(cc3Group).map(key => ({ ccthree_code: key, items: cc3Group[key] }));
          var cc3Index = this.cc3Data.findIndex(x=>x.ccthree_code == afe.ccthree_code)
          this.merged[serviceIndex][0].afes[afeIndex].ccthree_code = this.cc3Data[cc3Index];
          this.editAfe = 1;
        }
      })
      .then(response => {
        //this.editAfe = 0;
      }).catch(error => {
        console.log(error)
      })
    },
    get_services(id){
      console.log(id)
      this.$axios.get('/vendor/get/allowed_services/' + this.companyId.companyid + '/' + id)
      .then(response => {
        this.vendorServices = response.data.result;
        this.getUwis(this.vendorServices);
        this.vwref = 1;

        for (var i = 0; i < this.preset.sids.length; i++) {
          let random = Math.random();
          var match = this.vendorServices.filter(y=>y.serviceid == this.preset.sids[i].serviceid)

          var total = this.formatPrice((this.preset.sids[i].quantity * this.preset.sids[i].pu))
          this.serviceData.push({
            id: this.preset.sids[i].serviceid,
            type: match[0].type,
            name: this.preset.sids[i].service_name,
            serviceid: match[0],
            dates: {"start": this.$moment(this.preset.sids[i].start_date).toDate(), "end": this.$moment(this.preset.sids[i].end_date).toDate()},
            discount: this.preset.sids[i].discount,
            percentage: 100,
            quantity: this.preset.sids[i].quantity,
            uom: [this.preset.sids[i].pu, this.preset.sids[i].uom],
            pu: this.preset.sids[i].pu,
            total: total,
            vsid: random
          })

          for (var x = 0; x < this.preset.sids[i].afes.length; x++) {
            this.afes.push({
              vsid: random,
              service: this.preset.sids[i].service_name,
              category: this.preset.sids[i].afes[x].category,
              afenum: this.preset.sids[i].afes[x].afenum,
              id: this.preset.sids[i].serviceid,
              percentage: this.preset.sids[i].afes[x].percentage,
              service_ccid: this.preset.sids[i].afes[x].service_ccid,
              ccone_code: this.preset.sids[i].afes[x].ccone,
              cctwo_code: this.preset.sids[i].afes[x].cctwo,
              ccthree_code: this.preset.sids[i].afes[x].ccthree,
            })

          }
        }
      })
      .catch(error => {
      })
    },
    async addAfeToAllInvoiceServices(service) {
      if(this.percentage > 100 || this.percentage <= 0){
        this.$toasted.show('AFE Percentage Error', {type: 'error', duration: '3000'})
        return
      }
      var cc_num = [];
      if(this.createCC3Code !== undefined && this.createCC3Code !== null){
        cc_num = this.createCC3Code.items[0];
      }
      else {
        cc_num = this.createCC2Code.items[0];
      }

      if(this.afes.filter(e => e.service_ccid === cc_num.service_ccid).length > 0){
        this.$toasted.show('AFE Duplicate', {type: 'error', duration: '3000'})
        return;
      }

      this.createdServiceLine = 0;
      this.disableEdits = 0;
      this.lineLoading = true;

      for (var i = 0; i < this.serviceData.length; i++) {
        var service = this.serviceData[i];
        const tryImportAfe = await this.tryImportAfe(service, cc_num)
      };

      this.lineLoading = false;
    },
    getChain(serviceid){
      return this.$axios.get("/vendor/get/service_chains/" + this.companyId.companyid + "/" + this.projectid.projectid + "/" + serviceid)
      .then(response => {
        if(response.data.result.length > 0){
          return response.data.result;
        }
      }).catch(error => {
        console.log(JSON.stringify(error))
      })
    },
    async tryImportAfe(service, cc_num){
      console.log(service, cc_num);
      const getChain = await this.getChain(service.id);
      var match = [];

      if(getChain.length > 0){
        console.log(getChain)
        if(cc_num.cc_num == 3){
          match = getChain.filter(x=>x.afenum == cc_num.afenum && x.ccone_code == cc_num.ccone_code && x.cctwo_code == cc_num.cctwo_code && x.ccthree_code == cc_num.ccthree_code && x.serviceid == service.id)
          console.log(match)
        }
        else {
          match = getChain.filter(x=>x.afenum == cc_num.afenum && x.ccone_code == cc_num.ccone_code && x.cctwo_code == cc_num.cctwo_code && x.serviceid == service.id)
          console.log(match)
        }

        if(match.length > 0){
          console.log('match found')

          this.afes.push({
            vsid: service.vsid,
            afenum: match[0].afenum,
            ccone_code: match[0].ccone_code,
            cctwo_code: match[0].cctwo_code,
            ccthree_code: match[0].ccthree_code,
            percentage: this.percentage,
            service_ccid: match[0].service_ccid,
          })
          this.disableEdits = 0;
        }
        else {
          this.disableEdits = 0;
        }
      }
      else {
        this.disableEdits = 0;
      }
    },
    removeAll(){
      this.afes = [];
    },
    addNewUnassignedAfeLine(afe, service){
      console.log(service);
      this.$axios.get("/vendor/get/service_chains/" + service.serviceid.companyid + "/" + service.serviceid.projectid + "/" + service.id)
      .then(response => {
        if(response.data.result.length > 0){
          this.createdUnassignedServiceLine = afe;
          this.createdServiceLine = afe;
          this.createdServiceLineItem = service;
          this.percentage = 100;

          var result = this.lodash.groupBy(response.data.result, 'afenum')
          this.createAfeData = Object.keys(result).map(key => ({ afenum: key, items: result[key] }));
          this.createAfenum = this.createAfeData[0];
          this.createAfe = 1;
          this.disableEdits = 1;
          return this.createAfeData[0];
        }
        else {
          this.createdServiceLine = 0;
          this.$toasted.show('No AFES Found', {type: 'error', duration: '3000'})
          return
        }
      }).then(response => {
        //cc1
        var afeData = this.lodash.groupBy(response.items, 'ccone_code');
        this.cc1CreateData = Object.keys(afeData).map(key => ({ ccone_code: key, items: afeData[key] }));
        this.createCC1Code = this.cc1CreateData[0];
        this.createAfe = 1;
        return this.cc1CreateData[0]
      }).then(response => {
        //cc2
        var cc1 = this.lodash.groupBy(response.items, 'cctwo_code');
        this.cc2CreateData = Object.keys(cc1).map(key => ({ cctwo_code: key, items: cc1[key] }));
        this.createCC2Code = this.cc2CreateData[0];
        this.createAfe = 1;
        return this.cc2CreateData[0];
      }).then(response => {
        //cc3
        if(response.items.length > 0){
          var cc3Temp = response.items.filter(o => !o.ccthree_code.includes('---'));
          var cc3Group = this.lodash.groupBy(cc3Temp, 'ccthree_code');
          this.cc3CreateData = Object.keys(cc3Group).map(key => ({ ccthree_code: key, items: cc3Group[key] }));
          this.createCC3Code = this.cc3CreateData[0];
          this.createAfe = 1;
        }
      })
      .then(response => {
        this.createAfe = 0;
      }).catch(error => {
        console.log(error)
      })
  },
    getUwis(services) {
      for (var i = 0; i < services.length; i++) {
        this.$axios.get("/vendor/get/service_chains/" + this.companyId.companyid + "/" + this.projectid.projectid + "/" + services[i].serviceid)
        .then(response => {
          this.uwiData = this.uwiData.concat(response.data.result);
          if(i === services.length){
            var temp = this.lodash.uniqBy(this.uwiData, 'uwi');
            this.uwiData = temp.filter(x=>x.projectid === this.projectid.projectid);
          }
        })
        .catch(error => {
        })
      }
    },
    get_company(){
      this.$axios.get('/vendor/get/company_address/' + this.companyId.companyid)
      .then(response => {
        this.companyInfo = response.data;
      })
      .catch(error => {
      })
      this.$http.get('/get/company/avatar/' + this.companyId.companyid ).then(response => {
          if(response.data.avatar) {
            this.avatar = response.data.avatar;
          }
      }).catch(error => {
            //console.log("Error getting avatar " + error);
      })
    },
  },
  computed: {
    sortedServiceData: function () {
      //return this.lodash.sortBy(this.serviceData, ["number"])
      return this.serviceData;
    },
    subtotal: function(){
      return this.serviceData.reduce(function(total, item){
        return total + (item.quantity * item.pu) - ((item.discount/100) * item.quantity * item.pu);
      },0);
    },
    quickpaysubtotal: function() {
      return this.subtotal - ((this.quickpay/100) * this.subtotal);
    },
    gstsubtotal: function() {
      return this.subtotal * (this.gst/100);
    },
    qpgstsubtotal: function() {
      return this.quickpaysubtotal * (this.gst/100);
    },
    qpdiscount: function() {
      return ((this.quickpay/100) * this.subtotal)
    },
    totalinvoice: function() {
      return this.subtotal + this.gstsubtotal;
    },
    qptotalinvoice: function() {
      return this.quickpaysubtotal + this.qpgstsubtotal;
    },
    unassigned: function(){
      var unassigned = [];
      for (var i = 0; i < this.serviceData.length; i++) {
        var filtered = this.afes.filter(x=>x.vsid == this.serviceData[i].vsid)
        if(filtered.length == 0) {
          unassigned.push(this.serviceData[i])
        }
      }
      return unassigned
    },
    merged: function(){
      var dict = {};

      this.serviceData.forEach(service => {
        var filtered = this.afes.filter(x=>x.vsid == service.vsid)
        service.afes = [];
        filtered.forEach(afe => {
          service.afes.push({
            afenum: afe.afenum,
            ccone_code: afe.ccone_code,
            cctwo_code: afe.cctwo_code,
            ccthree_code: afe.ccthree_code,
            percentage: afe.percentage,
          })
        })
      })

      this.serviceData.forEach(service => {
        if(service.afes.length > 0){
          dict[JSON.stringify(service)] = []
        }
      });

      this.serviceData.forEach(service => {
        service.afes.forEach(afe => {
            dict[JSON.stringify(service)] = dict[JSON.stringify(service)].concat(afe);
        });
      })

      var dictAfe = {};

      for (var service in dict) {
        let serviceValue = dict[service]
        let key = JSON.stringify(dict[service]);
        if(!(key in dictAfe)){
          dictAfe[key] = [JSON.parse(service)];
        } else {
          dictAfe[key] = dictAfe[key].concat(JSON.parse(service));
        }
      }

      var temp = []

      for (var service in dictAfe) {
        temp.push(dictAfe[service]);
      }
      return temp;
    },
  },
  mounted: function() {
    let token = this.$session.get('jwt');
    var decoded = jwt_decode(token);

    this.$http.get('/get/vendor/avatar/' + decoded.id ).then(response => {
        this.vendorAvatar = response.data.avatar;
    }).catch(error => {
        console.log("Error getting avatar " + error);
    })

    this.$axios.get('/vendor/get/profile')
    .then(response => {
      this.vendorDetails = response.data;
    })
    .catch(error => {

    })
    this.$axios.get('/vendor/get/allowed_companies')
    .then(response => {
      this.companyData = response.data.result;
    })
    .catch(error => {

    })
  }
}

</script>

<style>
  .vue-dropzone > .dz-preview .dz-error-message {
    opacity: 100;
    left: 5% !important;
    top: 135px;
    width: 90%;
  }
  .vue-dropzone > .dz-preview .dz-remove {
    opacity: 100;
    width: 85%;
  }
  #services-modal #dataTable thead tr{

background-color:#464646;
color:white;

  }
 #services-modal #dataTable thead tr th:nth-child(2){

width:50%;
  }

   #services-modal #dataTable thead tr th:nth-child(3){

width:21%;

  }

   #services-modal #dataTable thead tr th:nth-child(4){

width:1%;
 
  }
  #services-modal #dataTable thead tr th:nth-child(5){

width:1%;
 
  }
  #service-th th{
      background-color: #464646;
      color:white;
  }

#date-picker-div {

    display: flex;
    justify-content: flex-end;
    margin: 0px 0px 10px 0px;
}

#date-picker-div  #date-picker {
  margin-right: 15px;
  }

#date-picker-div  span {
    margin-right: 10px;
    font-weight: bold;
  
  }

#date-picker-div  #date-picker input{
  
  font-size: 15.5px;

}

#date-picker-div  #date-picker button{

    font-size: 12.5px;

}

</style>
